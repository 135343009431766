.religionContainer .replyContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.religionContainer .descripción textarea {
  width: 55%;
  padding: 10px 5px;
  font-size: 1.1rem;
  border: 1px solid rgb(202, 202, 202);
  margin-bottom: 10px;
}

.religionContainer .replyContainer h3 {
  font-size: 1.2rem;
  font-weight: bold;
}

.religionContainer .descripción textarea{
  height: 10rem;
}

.religionContainer .withDropDownContent table tbody tr td{
  padding: 30px 0px;
}

.religionContainer .withDropDownContent table tr td:nth-child(1) {
  width: 50%;
}

.religionContainer .withDropDownContent table tr td:nth-child(2),
.religionContainer .withDropDownContent table tr td:nth-child(3),
.religionContainer .withDropDownContent table tr th:nth-child(2),
.religionContainer .withDropDownContent table tr th:nth-child(3) {
  text-align: center;
}
