.actionContent {
  display: flex;
  flex-direction: row;
  padding: 20px 0px
}

.actionContent button {
  margin-bottom: 0px;
}

.actionContent button:first-child {
  margin-right: 20px;
  background-color: unset;
  border: 1px solid #fff;
  color: #dc3545 !important;
}

.actionContent button:first-child:hover {
  border: 1px solid #dc3545;
  color: #dc3545 !important;
  box-shadow: unset;
}

.actionContent button:last-child {
  font-weight: bold;
  background-color: #36a7e8;
  border-color: #36a7e8;
}