.bodyContainer {
    /* border: 1px solid red; */
    padding: 0 2%;
    display: flex;
    flex-direction: column;
  }
  
  .bodyContainer .card .reactTable {
    display: block;
  }
  
  .bodyContainer .card .reactCardMobile {
    display: none !important;
  }
  
  .bodyContainer .card .headerContainer {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .bodyContainer .card .headerContainer > div {
    /* border: 1px solid red; */
    width: 33.33%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 10px 5px;
  }
  
  .bodyContainer .card .headerContainer > div > h2 {
    font-size: 1.5rem;
    margin-top: 0;
  }
  
  .bodyContainer .card .headerContainer > div:last-child {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }
  
  .bodyContainer .card .headerContainer > div > button {
    /* border: 1px solid red; */
    height: 50px;
    margin: 0;
    width: 50%;
    background-color: #333333;
    font-weight: bold;
    text-transform: uppercase;
    box-shadow: unset;
    border-color: #333333;
  }
  
  @media only screen and (max-width: 600px) {
    .bodyContainer {
      padding: unset;
    }
  
    .bodyContainer .card .reactTable {
      display: none;
    }
  
    .bodyContainer .card .reactCardMobile {
      display: block !important;
      border-top: 1px solid rgb(206, 206, 206);
    }
  
    .bodyContainer .card .reactCardMobile,
    .bodyContainer .card .card-body {
      background-color: rgba(203, 203, 210, 0.15);
    }
  
    .bodyContainer .card hr {
      display: none;
    }
  
    .bodyContainer > h1 {
      font-size: 1.8rem;
      font-weight: bold;
    }
  
    .bodyContainer .card {
      border: none !important;
    }
  
    .bodyContainer .card .card-body {
      padding: 0 !important;
    }
  
    .bodyContainer > h1,
    .bodyContainer > p,
    .bodyContainer .card .headerContainer  {
      padding: 0 12px;
    }
  
    .bodyContainer .card .headerContainer {
      display: flex;
      flex-direction: column;
    }
  
    .bodyContainer .card .headerContainer > div {
      width: 100%;
      margin: 5px 0 0 0;
    }
  
    .bodyContainer .card .headerContainer > div > h2 {
      font-size: 1rem;
      font-weight: bold;
    }
  
    .bodyContainer .card .headerContainer .buttonContainer {
  
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    .bodyContainer .card .headerContainer .buttonContainer > button {
      width: 100%;
      font-size: 0.8rem;
    }
  }
  
  .createVisitBtnContainer {
    width: 100%;
    display: flex;
    justify-content: end;
    margin-top: -20px;
  }
  
  .createVisitBtn {
    width: 30%;
    height: 45px;
    background-color: #36A7E8;
    color: white;
    font-weight: 500;
    border: none;
    margin-bottom: 20px;
    border-radius: 2px;
  }
  
  .filters {
    visibility: hidden;
  }
  