.container-spinner {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.container-spinner span:first-child {
  color: #0096ed;
}
