.pagination {
  margin: 0;
  white-space: nowrap;

  &--small {
    font-size: 12px;

    &__item {
      padding: 2px 10px;
    }
  }


  &__list {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
    margin: 2px 0;
    white-space: nowrap;
    justify-content: flex-end;
  }

  &__item {
    background-color: #FFFFFF;
    border: 1px solid #DDDDDD;
    color: inherit;
    float: left;
    line-height: 1.42857;
    margin-left: -1px;
    padding: 4px 10px;
    position: relative;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      background-color: #fcfcfc;
    }

    &--disabled {
      color: #A7B1C2;
      pointer-events: none;
      cursor: auto;
      background-color: #f4f4f4;
      border-color: #dee2e6;
    }

    &--selected {
      z-index: 1;
      color: #fff;
      background-color: #1c84c6;
      border-color: #1c84c6;

      &:hover {
        background-color: #1c84c6;
      }
    }

    &__previous {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &__next {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
}
