.badge {
  background-color: #D1DADE;
  color: #5E5E5E;
  font-size: 12px;
  font-weight: 600;
  padding: 4px 6px;
  text-shadow: none;
  white-space: nowrap;

  &--large {
    padding: 8px 12px;
  }

  &-outline {
    color: inherit;
    background-color: transparent;
    transition: all .5s;

    &.badge-primary {
      color: #1c84c6;
      border: 1px solid #1c84c6;
      background-color: #FFFFFF;
    }
    &.badge-success {
      color: #1ab394;
      border: 1px solid #1ab394;
      background-color: #FFFFFF;
    }
    &.badge-info {
      color: #23c6c8;
      border: 1px solid #23c6c8;
      background-color: #FFFFFF;
    }
    &.badge-warning {
      color: #f8ac59;
      border: 1px solid #f8ac59;
      background-color: #FFFFFF;
    }
    &.badge-danger {
      color: #ed5565;
      border: 1px solid #ed5565;
      background-color: #FFFFFF;
    }
    &.badge-inverse {
      color: #676a6c;
      border: 1px solid #676a6c;
      background-color: #FFFFFF;
    }
    &.badge-light {
      color: #f3f3f4;
      border: 1px solid #f3f3f4;
      background-color: #FFFFFF;
    }
  }

  &-success {
    background-color: #1ab394;
    color: #FFFFFF;
  }

  &-primary {
    background-color: #1c84c6;
    color: #FFFFFF;
  }

  &-info {
    background-color: #23c6c8;
    color: #FFFFFF;
  }

  &-warning {
    background-color: #f8ac59;
    color: #FFFFFF;
  }

  &-danger {
    background-color: #ed5565;
    color: #FFFFFF;
  }

  &-inverse {
    background-color: #676a6c;
    color: #FFFFFF;
  }

  &-light {
    background-color: #f3f3f4;
    color: #5E5E5E;
  }

  &-dark {
    background-color: #3a3f51;
    color: #FFFFFF;
  }
}