.dropDownHeader .react-select {
  width: 100%;
}

.dropDownHeader 
.react-select 
span {
  display: none;
}

@media (max-width: 800px){
  .dropDownHeader {
    /* border: 1px solid red; */
    display: flex;
    justify-content: flex-end;
  }

  .dropDownHeader .react-select {
    width: 70%;

  }
}