.referrals-table {
  position: relative;
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  background-color: #fff;

  .selected-row {
    background-color: #feffbf;
    transition: all .5s;
  }

  .view-cell {
    padding: 8px 12px;

    &__icon {
      margin-right: 12px;
    }
  }

  .chevron-cell {
    padding: 10px 0 10px 20px;
  }

  .email-cell {
    max-width: 260px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .merged-cell {
    vertical-align: top;
    padding-bottom: 0;

    &--bottom {
      padding-top: 0;
    }
    &--top {
      padding-bottom: 0;
    }
  }

  .border-none td {
      border: none !important;
  }

  .row--selected {
    cursor: pointer;
    :hover {
      background-color: #f5f5f5;
    }
  }

  .row--other {
    display: none;

    &.show {
      display: table-row;
    }
  }

  tbody tr {
    cursor: pointer;
    &:hover {
      td {
        background-color: rgba(0,0,0,0.05);
      }
    }
  }

  tbody tr td  {
    font-size: 14px;
    vertical-align: middle;
  }

  thead tr *:not(:first-child)::before  {
    content: "";
    margin: 0;
  }

  tbody tr td:first-child {
    font-weight: 400 !important;
  }

  th {
    line-height: 1.42857;
    padding: 8px;
    vertical-align: top;
    font-weight: 600 !important;
    font-size: 14px;
  }

  td {
    border-top: 1px solid #e7eaec;
    line-height: 1.42857;
    padding: 8px;
    vertical-align: top;

    .button:not(:first-of-type) {
      margin-left: 12px
    }
  }
}


.referral-row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-weight: 400;

  &__id {
    font-size: 14px;
    color: rgba(0, 0, 0, .6);
  }

  &__name {
    font-size: 16px;
    font-weight: 600;
  }

  &__date {
    margin-top: 4px;
    font-size: 14px;
    color: rgba(0, 0, 0, .6);
  }
}
