$color-white: #fff;
$color-primary: #1c84c6;
$color-success: #1ab394;
$color-info: #23c6c8;
$color-warning: #f8ac59;
$color-danger: #ed5565;
$color-gray: #f3f3f4;
$color-gray-light: #f8f8f9;
$color-gray-dark: #5E5E5E;
$color-gray-darker: #676a6c;
$color-gray-lighter: #dadadd;
$color-dark: #3a3f51;
$color-dark-light: #676a6c;
