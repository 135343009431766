.container {
  display: flex;
  flex-direction: column;
  width: 97%;
  margin: 0 auto;
}

.container h1 {
  font-size: 2rem;
  padding: 50px 0px;
}

.container .content {
  padding: 30px;
  display: grid;
  grid-template-columns: 250px 1fr 10%;
  grid-gap: 20px;
  background-color: #fff;
  margin-bottom: 20px;
  border: 1px solid rgb(230, 230, 230);
}

.content .leftSide {
  border-right: 1px solid rgb(231, 231, 231);
}

.content .leftSide p{
  font-size: 1.5rem;
  font-weight: bold;
}

.content .enableEdit button{
  font-weight: bold;
  background-color: #36a7e8;
  border-color: #36a7e8;
  text-transform: uppercase;
}