.react_modal_overlay {
    background: rgba(0, 0, 0, 0.5);

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    overflow: scroll;
  }

  .schoolsContainer{
    position: relative;

  }
  .got{
    background-color:  #e8ecf0;
    position: absolute;
    height: 10rem;
    overflow: auto;
    top: 60%;
    width: 100%;
  }

  .disabled{
    display: none;
  }

  .error{
    color: red;
    font-size: 18px;
  }


  .header_keep {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-weight: bold;

  }

  .header_keep p {
    font-weight: bold;
    text-align: center;
    width: 100%;
    font-size: 12px;
  }

  .header_keep h2{
    font-size: 20px;
    margin-top: 8%;
    color: black;
    font-weight: 600;
  }

  .header_close {
    display: flex;
  }

  .react_modal_content {
    width: 100%;
    max-width: 600px;
    height: 40%;
    background: white;
    padding: 0 3rem 3rem 3rem;
    position: relative;
    border-radius: 0.24rem;
    overflow: auto;
  }

  .input {
    width: 100%;
    padding: 0 1rem;
    height: 2.8rem;
    border-radius: 0.25rem;

    border: none;
    background: white;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.8);

    font-weight: 400;
    font-size: 1rem;

    margin-bottom: 1rem;
  }



  .modal_close{
    border: none;
    background: none;
  }

  .name_input::placeholder{
    color: rgba(0, 0, 0, 0.5);
  }

  .keep_button{
    margin-top: 1.5rem;
    border: none;
    width: 100%;
    background-color: #36a7eb;
    padding: 4px 12px;
    border-radius: 6px;
    color: white;
    font-weight: bold;
    height: 40px;
  }

  form p{
    color: gray;
    margin-bottom: .50rem;
  }

  form select{
    width: 100%;
    height: 2rem;

  }

  form h4{
    margin: 0;
    color: red;

  }






