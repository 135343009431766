.profile-content {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
}

.profile-content h1 {
  font-size: 1.8rem;
  padding: 10px;
}

.profile-content .playerContainer {
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-content .plans {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.profile-content .plans h1 {
  padding: 0;
}

.profile-content .plans .plans-container {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
}

.profile-content .card-footer {
  border-top: 1px solid #cecece;
  padding: 5px 10px;
}

.profile-content .card-footer p {
  font-weight: bold;
  margin: 0;
}

@media only screen and (max-width: 600px) {
  .profile-content {
    padding-bottom: 10px;
    margin-bottom: 0;
  }

  .profile-content hr {
    display: none;
  }

  .profile-content h1 {
    font-size: 1.5rem !important;
    margin: 0 0 10px 0 !important;
  }

  .profile-content .playerContainer .player {
    width: 100% !important;
    height: 14rem !important;
  }

  .profile-content .plans .plans-container {
    display: flex !important;
    flex-direction: column;
  }

  .profile-content .card-footer {
    padding: 5px 10px;
    margin: 0
  }
}