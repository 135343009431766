.NavBar__Container {
  display: none;
}

.justify-content-end .nav-options {
  margin-right: 50px;
}

.justify-content-end .nav-options ul {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0;
}

.justify-content-end .nav-options ul li {
  list-style: none;
  padding: 2px 8px;
}

.justify-content-end .nav-options ul li button {
  text-decoration: none;
  color: #585858;
  font-weight: bold;
  font-size: .9rem;
  border: unset;
  background-color: #fff;
  font-family: "Roboto","Helvetica Neue",Arial,sans-serif;
}

.dropdown-menu-right {
  left: -110px;
}

.navbar-search-form .input-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.navbar-search-form .input-group i {
  margin-right: 10px;
}

.navbar .container-fluid {
  display: flex;
  flex-direction: row-reverse;
}

.navbar .container-fluid button {
  box-shadow: none;
}

@media only screen and (max-width: 600px) {
  .NavBar__Container {
    display: block;
  }
}