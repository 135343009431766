.mainFeatures .replyContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.mainFeatures .descripción textarea {
  width: 55%;
  padding: 10px 5px;
  font-size: 1.1rem;
  border: 1px solid rgb(202, 202, 202);
  height: 10rem;
}

.mainFeatures input[readOnly] {
  background-color: rgb(230, 230, 230);
  color: gray;
}

.mainFeatures .replyContainer h3 {
  font-size: 1.2rem;
  font-weight: bold;
}

.mainFeatures .withDropDownContent {
  width: 55%;
}