.container-table table thead {
    background-color: #f0f0f0;
  }
  
  .container-table table thead tr th {
    color: #000 !important;
    font-weight: bold;
    padding: 20px 0px;
    font-size: 0.8rem;
  }
  
  .container-table table tbody tr td:first-child {
    font-weight: normal;
  }
  
  .type_column {
    p {
      color: black;
      font-weight: 900;
    }
  }
  
  .delete_btn {
    background-color: red;
    border: none;
    border-radius: 5px;
    width: 45%;
    height: 30%;
    font-weight: 500;
    padding: 5px;
  }
  
  .delete_icon {
    background-color: red;
    color: white;
    width: 30px;
    height: 20px;
  }
  