@import '../../styles/variables';

.dropdown-button {
  font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
  border-radius: 3px;
  font-size: inherit;
  color: inherit;
  background: $color-white;
  border: 1px solid #e7eaec;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // width: 130%;

  &--large {
    padding: 2px 12px;
    font-weight: 600;
  }

  &__container {
    position: relative;
  }

  &__menu {
    position: absolute;
    top: 33px;
    right: 1px!important;
    will-change: top, left;
    border: medium none;
    border-radius: 3px;
    box-shadow: 0 0 3px rgba(86, 96, 117, 0.7);
    background-color: #fff;
    float: left;
    list-style: none outside none;
    padding: 0;
    text-shadow: none;
    top: 100%;
    z-index: 1000;
    width: 165px;

    li {
      border-radius: 3px;
      color: inherit;
      line-height: 25px;
      margin: 4px;
      text-align: left;
      font-weight: normal;
      display: block;
      cursor: pointer;

      &:hover {
        color: #262626;
        text-decoration: none;
        background-color: #f5f5f5;
      }
    }
  }

  &__item {
    display: block;
    text-decoration: none;
    padding: 4px 20px;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    $color-white-space: nowrap;
    background-color: transparent;
    border: 0;
  }

  &--outline {
    color: inherit;
    background-color: transparent;
    transition: all .5s;

    &.dropdown-button--primary {
      color: $color-primary;
      background-color: $color-white;
    }

    &.dropdown-button--success {
      color: $color-success;
      background-color: $color-white;
    }

    &.dropdown-button--info {
      color: $color-info;
      background-color: $color-white;
    }

    &.dropdown-button--warning {
      color: $color-warning;
      background-color: $color-white;
    }

    &.dropdown-button--danger {
      color: $color-danger;
      background-color: $color-white;
    }

    &.dropdown-button--inverse {
      color: #676a6c;
      background-color: $color-white;
    }

    &.dropdown-button--light {
      color: #f3f3f4;
      background-color: $color-white;
    }
  }

  &--outline:hover {
    color: $color-white;
    background-color: $color-success;
  }

  &--default {
    background-color: #D1DADE;
    color: #5E5E5E;
  }

  &--default:hover {
    background-color: #D1DADE;
    color: #5E5E5E;
  }

  &--success {
    color: $color-white;
    background-color: $color-success;
    transition: all .5s;
    border-color: $color-success;
  }

  &--success:hover {
    color: $color-white;
    background-color: $color-success;
    border-color: $color-success;
  }

  &--primary {
    color: $color-white;
    background-color: $color-primary;
    transition: all .5s;
    border-color: $color-primary;
  }

  &--primary:hover {
    color: $color-white;
    background-color: darken($color-primary, 5);
    border-color: darken($color-primary, 5);
  }

  &--info {
    color: $color-white;
    background-color: $color-info;
    transition: all .5s;
    border-color: $color-info;
  }

  &--info:hover {
    color: $color-white;
    background-color: $color-info;
    border-color: $color-info;
  }

  &--warning {
    color: $color-white;
    background-color: $color-warning;
    transition: all .5s;
    border-color: $color-warning;
  }
  &--warning:hover {
    color: $color-white;
    background-color: $color-warning;
    border-color: $color-warning;
  }
  &--danger {
    color: $color-white;
    background-color: $color-danger;
    transition: all .5s;
    border-color: $color-danger;
  }
  &--danger:hover {
    color: $color-white;
    background-color: $color-danger;
    border-color: $color-danger;
  }
  &--inverse {
    color: $color-white;
    background-color: #676a6c;
    transition: all .5s;
    border-color: #676a6c;
  }
  &--inverse:hover {
    color: $color-white;
    background-color: #676a6c;
    border-color: #676a6c;
  }
  &--light {
    color: #5E5E5E;
    background-color: #f3f3f4;
    transition: all .5s;
    border-color: #f3f3f4;
  }
  &--light:hover {
    color: $color-white;
    background-color: #f3f3f4;
    border-color: #f3f3f4;
  }
  &--dark {
    color: $color-white;
    background-color: #3a3f51;
    transition: all .5s;
    border-color: #3a3f51;
  }
  &--dark:hover {
    color: $color-white;
    background-color: #3a3f51;
    border-color: #3a3f51;
  }
}
