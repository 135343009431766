.hideSidebar {
  position: absolute;
  z-index: 2;
  top: 10px;
  left: -15px;
}

button.hideSidebar {
  border: 1px solid rgba(0,0,0,0.2);
  z-index: 8;
  border-radius: 5px;
}

.header {
  padding: 24px 32px;
  background-color: #fff;
  margin: 32px 0;
  border: 1px solid #e7eaec;
}

.header h1 {
  font-size: 28px;
  font-weight: 500;
  margin: 0;
}

.header p {
  color: rgba(0,0,0,.8);
  margin: 0;
}