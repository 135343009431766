.table-edit-icon {
  border: none;
  background: transparent;
  opacity: 0.5;
  transition: 0.2s ease-in-out;
}

.table-edit-icon:hover {
  opacity: 1;
}

.table-container {
  overflow-y: auto;
  height: 75vh;
  width: 100%;
}

#users-table thead th {
  font-weight: bold !important;
  color: #343840 !important;
  padding: 0.5rem;
}

#users-table thead tr *:not(:first-child)::before {
  content:"|";
  margin-right: 0.5rem;
  color: #c4c4c4;
}
