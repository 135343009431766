.referrals {
  &__content {
    padding: 16px 32px;
    background-color: #fff;
    margin: 32px;
    border: 1px solid #e7eaec;
  }

  &__empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 200px;
    color: rgb(73, 80, 87);
    font-size: 16px;
    font-weight: 600;
  }
}
