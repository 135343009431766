.languagesContainer .replyContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.languagesContainer .descripción textarea {
  width: 55%;
  padding: 10px 5px;
  font-size: 1.1rem;
  border: 1px solid rgb(202, 202, 202);
  margin-bottom: 10px;
}

.languagesContainer .replyContainer h3 {
  font-size: 1.2rem;
  font-weight: bold;
}

.languagesContainer .descripción textarea{
  height: 10rem;
}

.languagesContainer .languagesSection {
  display: flex;
  flex-direction: column;
  width: 55%;
}

.languagesContainer .languagesSection > h4 {
  padding: 5px;
}

.languagesContainer .languagesSection > div {
  margin-bottom: 10px;
}