$lightest-background: rgb(250, 250, 250);
$light-background: #35a5e6;
$darker-background: #0272b3;
$darkest-background: #005b90;
$darkest-font: #004166;
$cancel-background: #df2702;

::-webkit-scrollbar {
  display: none;
}

.NavBar__Container {
  height: 42px;
}

.navbar-expand-lg {
  height: 42px;
}

.main-chat-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .content-header-container {
    width: 100%;
    height: 42px;
    background-color: $darker-background;

    .content-header {
      align-self: center;
      text-align: center;
      margin: 10px 0px;
      color: whitesmoke;
      font-weight: bold;
      font-size: large;
    }
  }

  .chat-data-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 95%;

    .chat-list-container {
      width: 30%;
      height: calc(100vh - 84px);
      overflow: scroll;
      padding: 2px 2px;
      // border-radius: 6px;
      background: $darkest-background;
      border: 2px solid $darkest-background;
      display: flex;
      flex-direction: column;

      .new-message-icon {
        color: white;
        flex-shrink: 0;
        cursor: pointer;
        float: right;
      }

      .chat-section-empty {
        color: white;
        margin: 0;
        padding: 1rem;
        font-size: 1rem;
        font-style: italic;
      }

      .rce-container-citem {
        cursor: pointer;
        width: 100%;
        min-height: 72px;

        .selected {
          border-style: solid;
          border-color: $darkest-background;
        }
      }

      .deleted {
        .rce-citem-body--bottom-title {
          font-style: italic;
        }
      }

      .selectedchat {
        > div {
          background: #eee;
        }
      }
    }
  }

  .clickable {
    cursor: pointer;
  }

  .button-ok {
    background-color: $darkest-background!important;
    color: white!important;
  }

  .button-cancel {
    background-color: $cancel-background!important;
    color: white!important;
  }

  .margin-bottom {
    margin-bottom: 5px;
  }

  .margin-bottom-10 {
    margin-bottom: 10px;
  }

  .margin-left {
    margin-left: 5px;
  }

  .hidden {
    display: none;
  }

  .centered-icon {
    margin-right: auto;
    margin-left: auto;
  }

  .darkest {
    color: $darkest-background;
  }

  .whitest {
    color: white;
  }

  hr {
    margin: 20px 0px;
  }

  @media (max-width: 790px) {
    .chat-data-container {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 95%;
  
      .chat-list-container {
        width: 100%;
        height: calc(100svh - 84px);
        position: absolute;
        z-index: 1;
      }
    }
  }
}