.vacantest-details {
  position: fixed;
  top: 0;
  right: 0;
  width: 600px;
  height: 100svh;
  background-color: #f7f7f8;
  border: 1px solid #e7eaec;
  box-shadow: 5px 0 15px rgba(0, 0, 0, 0.3);
  transform: translateX(100%);
  transition: transform 0.5s ease;

  &.show {
    transform: translateX(0%);
  }

  &__header,
  &__content {
    background-color: #fff;

    &__title {
      font-size: 18px;
      font-weight: 600;
      margin-top: 0;
      margin-bottom: 8px;
    }
    &__subtitle {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 16px;
      margin-bottom: 0;
    }

    &__close {
      position: absolute;
      top: 10px;
      right: 10px;
      color: #91928b;
      cursor: pointer;
    }
  }

  &__group {
    font-size: 14px;

    &--merge {
      font-size: 14px;
      display: flex;
      justify-content: space-between;

      dl:first-of-type {
        width: 40%;
      }
      dl:last-of-type {
        width: 60%;
      }
    }

    &--merge-header {
      margin-top: 20px;
      font-size: 14px;
      display: flex;
      justify-content: space-between;

      dl {
        width: 30%;
        margin-bottom: 0px!important;
      }
      dl:first-of-type {
        width: 40%;
      }
    }
  }
}
