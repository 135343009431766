.styles_dropDownHeader__3oDKO
.styles_react-select__1QnWV
.css-yk16xz-control,
.styles_dropDownHeader__3oDKO
.styles_react-select__1QnWV
.css-yk16xz-control
.css-g1d714-ValueContainer, 
.styles_dropDownHeader__3oDKO
.styles_react-select__1QnWV
.react-select__control{
  /* border: 1px solid red; */
  height: 50px;
}

.styles_dropDownHeader__3oDKO
.styles_react-select__1QnWV {
  /* border: 1px solid red !important; */
  width: 100% !important;
}

.styles_dropDownHeader__3oDKO
.styles_react-select__1QnWV
.css-yk16xz-control
.css-g1d714-ValueContainer
.css-1uccc91-singleValue {
  /* border: none; */
  color: rgb(56, 56, 56);
  font-size: 1.08rem;
}