:root {
  --toastify-color-progress-light: #0096ed;
}

.container-table {
  height: 400px !important;
  max-height: 500px !important;
  overflow-y: auto;
  margin: 30px 0;
  background-color: #f5f5f5;
  border-radius: 6px;
  width: 100%;
  border: 1px solid #cecece;
}

.container-table.ApplicationsImpossible {
  height: 400px !important;
  max-height: 500px !important;
  overflow-y: auto;
  margin: 30px 0;
  background-color: #f5f5f5;
  border-radius: 6px;
  width: 40%;
  border: 1px solid #cecece;
}

.container-table table > thead {
  /* position: -webkit-sticky;
  position: sticky; */
  top: 0px;
  z-index: 1;
  background-color: #f5f5f5;
}

.container-table table > thead > tr > th {
  color: #000;
  font-weight: bold;
  padding-top: 15px;
  padding-bottom: 15px;
  text-transform: capitalize;
  font-size: 0.9rem;
}

.container-table table > thead > tr > th::before {
  content: '';
  border: 1px solid rgb(196, 196, 196);
  margin-right: 10px;
}

.container-table table > thead > tr > th:first-child::before {
  border: unset;
}

.container-table table > tbody > tr {
  border-top: 1px solid #cecece;
  border-bottom: 1px solid #cecece;
}

.container-table table > tbody > tr > td {
  padding-left: 20px;
}

.container-table table > tbody > tr > td:last-child {
  /* border: 1px solid blue !important; */
  min-width: 250px
}

.container-table table > tbody > tr > td > div {
  width: 100%;
}

.container-table table > tbody > tr > td > button {
  border: none !important;
  border-color: unset !important;
  background-color: unset;
  box-shadow: unset !important;
  color: #167fe0;
  padding: 0;
}

.container-table table > tbody > tr > td > button:hover {
  border: none !important;
  border-color: unset !important;
  background-color: unset;
  box-shadow: unset !important;
  color: #2d75b9;
}

.container-table table > tbody > tr > td > button:focus {
  border: none !important;
  border-color: unset !important;
  background-color: unset;
  box-shadow: unset !important;
  color: #2d75b9;
}