.vacantests-filters {
  padding: 32px 0;
  display: flex;

  span {
    font-size: 14px;
  }

  > div {
    display: flex;
    align-items: center;
    margin-right: 32px;

    &:last-child {
      margin-left: auto;
    } 
  }

  &__dropdown {
    min-width: 300px!important;
    max-width: 700px!important;

    > div {
      min-width: 300px!important;
      max-width: 700px!important;
    }
  }

  &__checkbox {
    

    label {
      display: flex;
      align-items: center;

      input {
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }

    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;

    &__dropdown {
      width: 100%;
      margin-bottom: 16px;

      &:not(:last-child) {
        margin-right: 0;
      }
    }
  }
};
