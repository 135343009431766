.bodyContainer {
  padding: 0 40px;
}

.bodyContainer .card {
  padding: 10px 50px;
}

@media only screen and (max-width: 600px) {
  .bodyContainer {
    padding: 10px;
  }

  .bodyContainer .card {
    padding: 10px;
  }
}
