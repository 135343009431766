@import '../../styles/variables';

.button {
  font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
  border-radius: 3px;
  font-size: inherit;
  color: inherit;
  background: $color-white;
  border: 1px solid #e7eaec;

  &:hover {
    background: darken($color-white, 10%);
  }

  &--large {
    padding: 6px 10px;
  }

  &--transparent {
    background-color: transparent;
    border-color: transparent;
  }


  &--outline {
    color: inherit;
    background-color: transparent;
    transition: all .5s;

    &.button--primary {
      color: $color-primary;
      background-color: $color-white;
    }
    &.button--success {
      color: $color-success;
      background-color: $color-white;
    }
    &.button--info {
      color: $color-info;
      background-color: $color-white;
    }
    &.button--warning {
      color: $color-warning;
      background-color: $color-white;
    }
    &.button--danger {
      color: $color-danger;
      background-color: $color-white;
    }
    &.button--inverse {
      color: $color-dark-light;
      background-color: $color-white;
    }
    &.button--light {
      color: $color-gray;
      background-color: $color-white;
    }
  }

  &--outline:hover {
    color: $color-white;
    background-color: $color-success;
  }

  &--success {
    color: $color-white;
    background-color: darken($color-success, 1);
    transition: all .5s;
    border-color:  darken($color-success, 1);
  }

  &--success:hover {
    color: $color-white;
    background-color: $color-success;
    border-color: $color-success;
  }

  &--primary {
    color: $color-white;
    background-color: $color-primary;
    transition: all .5s;
    border-color: $color-primary;
  }

  &--primary:hover {
    color: $color-white;
    background-color: $color-primary;
    border-color: $color-primary;
  }

  &--info {
    color: $color-white;
    background-color: $color-info;
    transition: all .5s;
    border-color: $color-info;
  }

  &--info:hover {
    color: $color-white;
    background-color: $color-info;
    border-color: $color-info;
  }

  &--warning {
    color: $color-white;
    background-color: $color-warning;
    transition: all .5s;
    border-color: $color-warning;
  }

  &--warning:hover {
    color: $color-white;
    background-color: $color-warning;
    border-color: $color-warning;
  }

  &--danger {
    color: $color-white;
    background-color: $color-danger;
    transition: all .5s;
    border-color: $color-danger;
  }

  &--danger:hover {
    color: $color-white;
    background-color: $color-danger;
    border-color: $color-danger;
  }

  &--inverse {
    color: $color-white;
    background-color: $color-dark-light;
    transition: all .5s;
    border-color: $color-dark-light;
  }

  &--inverse:hover {
    color: $color-white;
    background-color: $color-dark-light;
    border-color: $color-dark-light;
  }

  &--light {
    color: $color-gray-dark;
    background-color: $color-gray-light;
    transition: all .5s;
    border-color: $color-gray-light;
  }

  &--light:hover {
    background-color: $color-gray-lighter;
    border-color: $color-gray-lighter;
  }

  &--dark {
    color: $color-white;
    background-color: $color-dark;
    transition: all .5s;
    border-color: $color-dark;
  }

  &--dark:hover {
    color: $color-white;
    background-color: $color-dark;
    border-color: $color-dark;
  }
}
