.buttonsContainer {
  display: flex;
  flex-direction: row;
  /* border: 1px solid blue; */
  align-items: center;
}

.buttonsContainer > button {
  margin: 0px;
}

.buttonsContainer > button:last-child {
  margin-left: 10px;
}

.buttonCancel {
  border: unset;
  background-color: unset;
  color: red;
}

.buttonCancel:hover {
  background-color: unset;
  border: 1px solid red;
  color: red;
}

.buttonCancel:focus {
  background-color: unset;
  border: 1px solid red;
  color: red;
}