.titleContent {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}


.titleContent h3 {
  font-size: 1.2rem;
  font-weight: bold;
}