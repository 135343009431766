.basicInfoContainer .replyContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.basicInfoContainer input,
.basicInfoContainer .descripción textarea {
  width: 55%;
  padding: 10px 5px;
  font-size: 1.1rem;
  border: 1px solid rgb(202, 202, 202);
}

.basicInfoContainer input[readOnly] {
  background-color: rgb(230, 230, 230);
  color: gray;
}

.basicInfoContainer .replyContainer h3 {
  font-size: 1.2rem;
  font-weight: bold;
}

.basicInfoContainer .replyContainer .areaUpdateLogo {
  display: flex;
  flex-direction: column;
  width: 35%;
}

.basicInfoContainer .replyContainer .areaUpdateLogo .upload__image-wrapper{
  width: 100%;
}

.basicInfoContainer .replyContainer .areaUpdateLogo .upload__image-wrapper > button {
  width: 100%;
  
}

.basicInfoContainer .replyContainer .areaUpdateLogo .upload__image-wrapper .image-item img { 
  width: 100%;
  margin-top: 15px;
  border: 1px solid red;
}

.basicInfoContainer .descripción textarea {
  height: 10rem;
}