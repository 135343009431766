.referral-row-mobile {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 12px;
  margin-bottom: 12px;

  > div {
    margin-bottom: 8px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 480px) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &--primary {
    background-color: #1c84c613;
  }

  &--success {
    background-color: #1ab39413;
  }

  &--warning {
    background-color: #f8ac5913;
  }

  &--disabled {
    background-color: #e7eaec13;
  }
}

.referral-row__field-label {
  font-weight: 500;
}

.referral-row__educ-levels__educ-level {
  display: flex;

  :not(:last-child) {
    margin-bottom: 8px;
  }
}

.referral-row__educ-levels__group {
  margin-right: 24px;
}

.referral-row__info {
  &__created-at {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
  }

  &__name {
    font-size: 16px;
    font-weight: 600;
  }
}

.referral-row__state {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  &__referral-id {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
  }
}

.referral-row__alert {
  margin-bottom: 0;
  margin-top: 8px;

  .badge {
    width: 100%;
  }
}
