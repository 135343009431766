
.tab-list {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  border-bottom: 1px solid #e7eaec;
}

.tab-item {
  border: 1px solid transparent;
  background: none;
  float: left;
  margin-bottom: -1px;
  margin-right: 8px;
  color: #A7B1C2;
  font-weight: 600;
  padding: 10px 20px;
  border-color: #dee2e6;
  cursor: pointer;
  border-bottom: none;

  &--active {
    color: #1c84c6;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
    cursor: auto;
  }

  &__notify {
    margin-left: 10px;
  }
}

.tab-content {
  padding: 20px;
  border: 1px solid #e7eaec;
  border-top: none;
  background: #fff;
  position: relative;
  display: none;

  &--active {
    display: block;
  }
}

.tab-body {
  background: #fff;
  border: 1px solid #e7eaec;
  border-top: none;
  border-radius: 2px;
  padding: 20px;
  position: relative;
}
