.plan {
  display: flex;
  flex-direction: column;
  border: 1px solid rgb(224, 224, 224);
}

.plan > h2,
.plan .characteristics {
  padding: 10px;
}

.plan > h2 {
  font-weight: bold;
  border-bottom: 1px solid #cecece;
}

.plan .characteristics {
  height: 100%;
  padding: 2rem;
}

.plan .characteristics .benefits {
  border-bottom: 1px solid #cecece;
  margin-bottom: 20px;
}

.plan .characteristics .benefits:last-child {
  border-bottom: unset;
}

.plan .characteristics .benefits > h5 {
  font-weight: 600;
  margin-bottom: 10px;
}

.plan .characteristics .benefits > p {
  font-size: 1.1rem;
  margin-bottom: 12px;
}

.plan .plan-status {
  border-top: 1px solid #cecece;
  display: flex;
  height: 200px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.plan .plan-status img {
  height: 50px;
  width: 50px;
  margin-bottom: 15px;
}

.plan .plan-status p {
  margin: 0;
  font-weight: 700;
}

.plan .plan-status button {
  font-size: 1.2rem;
}

@media only screen and (max-width: 600px) {
  .plan {
    margin-bottom: 20px;
  }

  .plan > h2 {
    font-size: 1.5rem;
  }

  .plan .plan-status {
    /* border: 1px solid red; */
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

  .plan .plan-status button {
    margin: 0;
  }
}